import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'

import pic_covid_omics_kitchen from '../assets/images/covid-omics-kitchen.png'
import pic_miRNA_Atlas from '../assets/images/thumbnail_miRNA_atlas.png'

class Generic extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="KitchenLab &bull; News" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <h2>News</h2>

            <div class="blog-card" style={{ width: '100%' }}>
              <div class="meta photo">
                <img
                  src={pic_covid_omics_kitchen}
                  width="200"
                  height="200"
                ></img>
              </div>
              <div class="description">
                <h1>COVID-19 antibody test performance</h1>
                <p>
                  In an effort to support the work evaluating technologies for
                  serology (antibody) testing, the lab has developed an
                  interactive webapp to explore the effect of changing
                  prevalence on the population-level performance of these tests.
                </p>
                <p></p>
                <ul className="actions small_card">
                  <li>
                    <Link
                      to="https://covid.omics.kitchen"
                      className="button small"
                      target="_blank"
                    >
                      covid.omics.kitchen
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://covidinnovation.partners.org/evaluation/"
                      className="button small"
                      target="_blank"
                    >
                      covidinnovation.partners.org
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <p></p>
            <div class="blog-card alt">
              <div class="meta photo">
                <img src={pic_miRNA_Atlas} width="230" height="200"></img>
              </div>
              <div class="description">
                <h1>miRNA Tissue Atlas</h1>
                <p>
                  Good RNA biomarkers may be specifically expressed in tissues
                  relevant to the disease under investigation. In collaboration
                  with the van Keuren-Jensen lab at TGen, we have created a
                  human small-RNA tissue atlas.
                </p>
                <p></p>
                <ul className="actions small_card">
                  <li>
                    <Link
                      to="https://microrna-atlas.omics.kitchen"
                      className="button small"
                      target="_blank"
                    >
                      microrna-atlas.omics.kitchen
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="http://data.omics.kitchen/miRNAatlas/"
                      className="button small"
                      target="_blank"
                    >
                      Interrogate specific miRNAs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section id="content2" className="main">
            <h2>Publications</h2>
            <p>
              A complete list of publications is maintained at the{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://connects.catalyst.harvard.edu/Profiles/display/Person/184153"
              >
                Harvard Catalyst
              </a>
              , as well as{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://pubmed.ncbi.nlm.nih.gov/?term=Robert%20Kitchen%5BFull%20Author%20Name%5D%20OR%20Rob%20Kitchen%5BFull%20Author%20Name%5D&sort=pubdate&size=20"
              >
                PubMed
              </a>
              , and{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://scholar.google.com/citations?user=Fnrm6iwAAAAJ&hl=en"
              >
                Google Scholar
              </a>
              .
            </p>
            <iframe
              src="https://connects.catalyst.harvard.edu/Profiles/display/Person/184153"
              style={{ height: '1000px', width: '100%' }}
              title="Harvard Catalyst Profile"
            ></iframe>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
